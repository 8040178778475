<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="会员昵称：">
        <el-input v-model="searchForm.nickname" size="small" placeholder="请输入会员昵称"></el-input>
      </el-form-item>
      <el-form-item label="会员身份：">
        <el-select v-model="searchForm.identity_type" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="个人会员" :value="0"></el-option>
          <el-option label="企业会员" :value="1"></el-option>
          <el-option label="个人会员商家" :value="2"></el-option>
          <el-option label="企业会员商家" :value="3"></el-option>
          <el-option label="供应商品商家" :value="4"></el-option>
          <el-option label="代销商家" :value="5"></el-option>
          <el-option label="产品合伙人" :value="6"></el-option>
          <el-option label="城市合伙人" :value="7"></el-option>
          <el-option label="市场合伙人" :value="8"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input v-model="searchForm.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input v-model="searchForm.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader v-model="searchForm.city_id" :props="{ value: 'id', label: 'name', children: '_child' }" clearable filterable :options="cityList" size="small"></el-cascader>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="nickname" label="用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.user_info.nickname" :avatar="row.user_info.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="identity_type" label="会员身份" width="200" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type | identityName }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="联系人" width="200" align="center"></el-table-column>
      <el-table-column prop="mobile" label="联系电话" width="200" align="center"></el-table-column>
      <el-table-column prop="city_name" label="所属区域" width="200" align="center">
        <template v-slot="{ row }">{{ row.province }}{{ row.city }}{{ row.area }}</template>
      </el-table-column>
      <el-table-column prop="status" label="申请状态" width="200" align="center">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '已通过' : row.status == 0 ? '待审核' : row.status == -1 ? '入驻费用待支付' : row.status == 2 ? '已驳回' : row.status == 3 ? '已停用' : '已撤销' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="申请时间" width="200" align="center">
        <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="seeApply(row)" type="text" size="small">查看申请</el-button>
          <el-button @click="audit(row)" type="text" size="small" v-if="row.status == 0">审核</el-button>
          <el-button @click="(reject_reason = row.reject_reason), (showReject = !0)" type="text" size="small" v-if="row.status == 2">驳回原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="驳回原因" :visible.sync="showReject" width="650px">
      {{ reject_reason }}
    </el-dialog>
    <el-dialog title="审核" :visible.sync="showAudit" width="650px">
      <el-form ref="form" :model="auditForm" :rules="rules" label-width="100px">
        <el-form-item label="审核结果：">
          <el-radio-group v-model="auditForm.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提现费率：" prop="withdraw_rate" v-if="auditForm.status == 1">
          <el-input v-model="auditForm.withdraw_rate"></el-input>
        </el-form-item>
        <el-form-item label="驳回原因：" prop="reject_reason" v-else>
          <el-input v-model="auditForm.reject_reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAudit = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看申请" :visible.sync="showAuditInfo" width="650px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="会员信息" name="1">
          <el-form label-width="110px" class="apply-info">
            <el-form-item label="用户信息：">
              <userInfo :name="currentInfo.user_info.nickname" :avatar="currentInfo.user_info.avatar"></userInfo>
            </el-form-item>
            <el-form-item label="申请身份：">{{ auditInfo.identity_type | identityName }}</el-form-item>
            <el-form-item label="身份证：" v-if="auditInfo.identity_type == 2">
                 <el-image style="width: 120px; height: 70px" :src="auditInfo.id_card_image_z" fit="cover" :preview-src-list="[auditInfo.id_card_image_z,auditInfo.id_card_image_f]"></el-image>
                  <el-image style="width: 120px; height: 70px" :src="auditInfo.id_card_image_f" fit="cover" :preview-src-list="[auditInfo.id_card_image_z,auditInfo.id_card_image_f]"></el-image>
            </el-form-item>
            <el-form-item label="企业名称：" v-if="auditInfo.identity_type > 0">{{ auditInfo.enterprise_name }}</el-form-item>
            <el-form-item label="营业执照：" v-if="auditInfo.identity_type != 0 && auditInfo.identity_type != 2">
              <el-image style="width: 120px; height: 70px" :src="auditInfo.license" fit="cover" :preview-src-list="[auditInfo.license]"></el-image>
            </el-form-item>
            <el-form-item label="联系人：">{{ auditInfo.name }}</el-form-item>
            <el-form-item label="联系电话：">{{ auditInfo.mobile }}</el-form-item>
            <template v-if="auditInfo.identity_type == 1">
              <el-form-item label="开户行：">{{ auditInfo.deposit_bank }}</el-form-item>
              <el-form-item label="开户账号：">{{ auditInfo.deposit_account }}</el-form-item>
            </template>
            <el-form-item label="所属区域：">{{ auditInfo.province }}{{ auditInfo.city }}{{ auditInfo.area }}</el-form-item>
            <el-form-item label="收货地址：" v-if="auditInfo.identity_type == 0 || auditInfo.identity_type == 1">{{ auditInfo.address }}</el-form-item>
            <el-form-item label="供应商品描述：" v-if="auditInfo.identity_type == 4">{{ auditInfo.supply_goods_desc }}</el-form-item>
            <el-form-item label="代销商品描述：" v-if="auditInfo.identity_type == 5">{{ auditInfo.commission_goods_desc }}</el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="店铺信息" name="2" v-if="auditInfo.identity_type > 1">
          <el-form label-width="90px" class="apply-info">
            <el-form-item label="店铺名称：">{{ auditInfo.shop_name }}</el-form-item>
            <el-form-item label="店铺logo：">
              <el-image style="width: 120px; height: 70px" :src="auditInfo.shop_logo" fit="cover" :preview-src-list="[auditInfo.shop_logo]"></el-image>
            </el-form-item>
            <el-form-item label="详细地址：">{{ auditInfo.address }}</el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </el-main>
</template>

<script>
import userInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      activeName: '1',
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        name: '',
        mobile: '',
        type: '',
        identity_type: '',
        city_id: '',
        start_time: '',
      },
      cityList: [],
      list: [],
      total_number: 0,
      showAudit: !1,
      showAuditInfo: !1,
      showReject: !1,
      auditInfo: {},
      reject_reason: '',
      currentInfo: {
        user_info: {},
      },
      auditForm: {
        id: '',
        status: 1,
        withdraw_rate: '',
        reject_reason: '',
      },
      rules: {
        withdraw_rate: { required: true, message: '请填写提现费率', trigger: 'blur' },
        reject_reason: { required: true, message: '请填写驳回原因', trigger: 'blur' },
      },
    };
  },
  filters: {
    getDateformat: getDateformat,
    identityName(type) {
      let name = '';
      switch (type) {
        case 0:
          name = '个人会员';
          break;
        case 1:
          name = '企业会员';
          break;
        case 2:
          name = '个人会员商家';
          break;
        case 3:
          name = '企业会员商家';
          break;
        case 4:
          name = '供应商品商家';
          break;
        case 5:
          name = '代销商家';
          break;
        case 6:
          name = '产品合伙人';
          break;
        case 7:
          name = '城市合伙人';
          break;
        case 8:
          name = '市场合伙人';
          break;
      }
      return name;
    },
  },
  created() {
    this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
      if (res.code == 0) {
        let list = res.result.list;
        this.cityList = list;
      }
    });
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        name: '',
        mobile: '',
        type: '',
        identity_type: '',
        city_id: '',
        start_time: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    audit(row) {
      this.auditForm.id = row.id;
      this.auditForm.status = 1;
      this.auditForm.withdraw_rate = '';
      this.auditForm.content = '';
      this.showAudit = !0;
    },
    confirmAudit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            id: this.auditForm.id,
            status: this.auditForm.status,
          };
          if (data.status == 1) data.withdraw_rate = this.auditForm.withdraw_rate;
          if (data.status == 2) data.reject_reason = this.auditForm.reject_reason;
          this.$axios.post(this.$api.gateway.member.audit, data).then(res => {
            if (res.code == 0) {
              this.$message.success('处理成功');
              this.getList();
              this.showAudit = !1;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    seeApply(row) {
      this.$axios
        .post(this.$api.gateway.member.auditInfo, {
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.activeName = '1';
            this.auditInfo = res.result;
            this.currentInfo = row;
            this.showAuditInfo = !0;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.start_time = searchForm.start_time[1] / 1000;
        searchForm.end_time = searchForm.start_time[0] / 1000;
      }
      if (searchForm.city_id) {
        searchForm.province_id = searchForm.city_id[0];
        searchForm.area_id = searchForm.city_id[2];
        searchForm.city_id = searchForm.city_id[1];
      }
      this.$axios.post(this.$api.gateway.member.apply, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.apply-info {
  .el-form-item {
    margin: 0;
  }
}
</style>